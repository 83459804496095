import React from 'react';
// import { Link } from 'gatsby'

import { useFirebase } from 'components/firebase/Firebase';
import "./SignOut.scss";

const SignOutButton = () => {
  const firebase = useFirebase()

  return (
    <div id="signout"> 
      <a type="button" onClick={firebase.doSignOut}>Sign Out</a>
    </div>
  );
}

export default SignOutButton
