const ROUTES = {
  LANDING: "/",
  ABOUT: "/about",
  QUERY: "/query",
  DATA_VIZ_PAGE: "/visualizations",
  DATA_DOWNLOAD: "/data-download",
  DATASETS: "/data-download/datasets",
  API: "/data-download/api",

  ACCOUNT: "/account",
  // ADMIN: "/admin",
  PASSWORD_FORGET: "/password-forget",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  VERSION: "/version",
}

export default ROUTES
