// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-new-tsx": () => import("./../../../src/pages/about-new.tsx" /* webpackChunkName: "component---src-pages-about-new-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-data-download-api-tsx": () => import("./../../../src/pages/data-download/api.tsx" /* webpackChunkName: "component---src-pages-data-download-api-tsx" */),
  "component---src-pages-data-download-datasets-dynamic-datasets-js": () => import("./../../../src/pages/data-download/datasets/dynamic-datasets.js" /* webpackChunkName: "component---src-pages-data-download-datasets-dynamic-datasets-js" */),
  "component---src-pages-data-download-datasets-tsx": () => import("./../../../src/pages/data-download/datasets.tsx" /* webpackChunkName: "component---src-pages-data-download-datasets-tsx" */),
  "component---src-pages-data-download-index-tsx": () => import("./../../../src/pages/data-download/index.tsx" /* webpackChunkName: "component---src-pages-data-download-index-tsx" */),
  "component---src-pages-data-download-new-index-tsx": () => import("./../../../src/pages/data-download-new/index.tsx" /* webpackChunkName: "component---src-pages-data-download-new-index-tsx" */),
  "component---src-pages-index-new-tsx": () => import("./../../../src/pages/index-new.tsx" /* webpackChunkName: "component---src-pages-index-new-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-password-forget-tsx": () => import("./../../../src/pages/password-forget.tsx" /* webpackChunkName: "component---src-pages-password-forget-tsx" */),
  "component---src-pages-policies-tsx": () => import("./../../../src/pages/policies.tsx" /* webpackChunkName: "component---src-pages-policies-tsx" */),
  "component---src-pages-query-tsx": () => import("./../../../src/pages/query.tsx" /* webpackChunkName: "component---src-pages-query-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-version-tsx": () => import("./../../../src/pages/version.tsx" /* webpackChunkName: "component---src-pages-version-tsx" */),
  "component---src-pages-visualizations-tsx": () => import("./../../../src/pages/visualizations.tsx" /* webpackChunkName: "component---src-pages-visualizations-tsx" */)
}

