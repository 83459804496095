import React, { useContext } from 'react'
import { initializeApp } from '@firebase/app';
import { 
  browserLocalPersistence, 
  browserSessionPersistence, 
  createUserWithEmailAndPassword,
  // getAuth,
  indexedDBLocalPersistence, 
  initializeAuth, 
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword
} from '@firebase/auth';
import { getDatabase, ref as dbRef } from '@firebase/database';

import isBrowser from 'utils/isBrowser';

const config = { //get the values from the environment file
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_MESSAGING_APP_ID,
};

export class Firebase {
  constructor() {
    if(isBrowser) {
      this.app = initializeApp(config); //initialize firebase with the environment variables
      // this.auth = getAuth(this.app)
      //https://github.com/firebase/firebase-js-sdk/issues/4946#issuecomment-921147692
      this.auth = initializeAuth(this.app, {
        persistence: [
           indexedDBLocalPersistence,
           browserLocalPersistence,
           browserSessionPersistence
         ],
       })
      this.db = getDatabase(this.app)
    }
  }
  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) => createUserWithEmailAndPassword(this.auth, email, password)

  doSignInWithEmailAndPassword = (email, password) => signInWithEmailAndPassword(this.auth, email, password)

  doSignOut = () => signOut(this.auth)

  doPasswordReset = email => sendPasswordResetEmail(this.auth,email)

  doPasswordUpdate = password => updatePassword(this.auth.currentUser,password)

  // *** User API ***
  user = uid => dbRef(this.db, `users/${uid}`)

  // users = () => this.db.ref('users')
}
// export default Firebase;


export const FirebaseInstance = new Firebase()




export const FirebaseContext = React.createContext(null); //this creates a context to access Firebase
export const useFirebase = () => useContext(FirebaseContext)
